import "./asset/main.css";

import React from "react";

import { Pages } from "./pages";
import { ToastContainer } from "./shared/Toast";

export const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Pages />
    </>
  );
};
