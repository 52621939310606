import * as React from 'react';
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from '@mui/icons-material/Logout';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const Sidebar = [
    {
        id: 1,
        name: "QRCode",
        icon: <QrCodeScannerIcon />,
        activeIcon: <QrCodeScannerIcon />,
        path: "/",
    },
    {
        id: 2,
        name: "Dashboard",
        icon: <DashboardIcon />,
        activeIcon: <DashboardIcon />,
        path: "/dashboard",
        listOpenIcon: "/assets/icons/RightAngleArrow.svg",
    },

    {
        id: 3,
        name: "Promotion",
        icon: <ProductionQuantityLimitsIcon></ProductionQuantityLimitsIcon>,
        activeIcon: <ProductionQuantityLimitsIcon></ProductionQuantityLimitsIcon>,
        path: "/promotion",
    },
    {
        id: 4,
        name: "Settings",
        icon: <SettingsIcon />,
        activeIcon: <SettingsIcon />,
        path: "/settings/messages",
        subMenu: [
            {
                id: 6,
                name: "SettingMSG",
                icon: <SettingsIcon />,
                activeIcon: <SettingsIcon />,
                path: "/settings/messages",
            },
            {
                id: 7,
                name: "SizeCode",
                icon: <SettingsIcon />,
                activeIcon: <SettingsIcon />,
                path: "/settings/sizecode",
            },
            {
                id: 8,
                name: "AutomateMSG",
                icon: <SettingsIcon />,
                activeIcon: <SettingsIcon />,
                path: "/settings/automate-message",
            }
            // Add more sub-menu items if needed
        ]
    },
    {
        id: 5,
        name: "Logout",
        icon: <LogoutIcon></LogoutIcon>,
        activeIcon: <LogoutIcon></LogoutIcon>,
        path: "/login",
    },
];

export default Sidebar
