import React from "react";
import { Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "../utils";
import { Login } from "./Login";
import { Dashboard } from "./Dashboard";

export const Pages: React.FC = () => {
  return (
    <Routes>

      <Route path='/login' element={<Login />} />

      <Route element={<ProtectedRoute />}>
        <Route path='/dashboard' element={<Dashboard />} />
      </Route>

      <Route path='*' element={<div>Not Found</div>} />
    </Routes>
  );
};
