import React, { createContext, PropsWithChildren, useContext } from "react";

import { AuthStore } from "./AuthStore";
import { OrderStore } from "./OrderStore";

export class RootStore {
  public auth: AuthStore = new AuthStore();
  public order: OrderStore = new OrderStore();
}

const StoreContext = createContext<RootStore>({} as RootStore);

export const useStore = (): RootStore => useContext(StoreContext);

export const StoreProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return <StoreContext.Provider value={new RootStore()}>{children}</StoreContext.Provider>;
};
