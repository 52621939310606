import { Button, Card, CardContent, Dialog, DialogContent, FormGroup, TextField, Typography } from "@mui/material";
import React from "react";

function OrderAdd({ showLabel, handleCloseLabel }: any) {
  return (
    <div>
      <Dialog open={showLabel} onClose={handleCloseLabel}>
        <DialogContent>
          <Card style={{ width: "28rem" }}>
            <CardContent>
              <Typography sx={{ textAlign: "center", fontSize: "1.3rem", mb: 2 }}>Create Label</Typography>
              <form onSubmit={formik.handleSubmit}>
                <FormGroup sx={{ mb: 1.5 }}>
                  <Typography sx={{ mb: 0.5 }}>SubOrder ID</Typography>
                  <TextField
                    name='suborder_id'
                    size='small'
                    value={formik.values.suborder_id}
                    onChange={formik.handleChange}
                    placeholder='Suborder_id'
                  />
                  {formik.errors.suborder_id && typeof formik.errors.suborder_id === "string" && (
                    <p className='formikError'>{formik.errors.suborder_id}</p>
                  )}
                </FormGroup>

                <FormGroup sx={{ mb: 1.5 }}>
                  <Typography sx={{ mb: 0.5 }}>Mobile Number</Typography>
                  <TextField
                    name='mobileNumber'
                    size='small'
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    placeholder='Dispatch Date'
                  />
                  {formik.errors.mobileNumber && typeof formik.errors.mobileNumber === "string" && (
                    <p className='formikError'>{formik.errors.mobileNumber}</p>
                  )}
                </FormGroup>

                <Button style={{ width: "100%" }} variant='contained' color='success' type='submit'>
                  create
                </Button>
              </form>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderAdd;
