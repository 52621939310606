import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useStore } from "../store";
import Sidebar from "../components/Sidebar/Sidebar";
import { Box } from "@mui/material";

export const ProtectedRoute: React.FC = observer(() => {
  const { auth } = useStore();

  useEffect(() => {
    auth
      .me()
      .then(() => {
        console.log("User is authenticated");
      })
      .catch(() => {
        console.log("User is not authenticated");
      });
  }, []);

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  if (!auth.user) {
    return <Navigate to='/login' />;
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#f3f0f0" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          width: "84%",
          p: 1,
          mt: 0.8,
          mr: 0.5,
          ml: 0.5
        }}
      >

        {/* Rendering of child components */}
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "14px", // Add border radius
            overflow: "auto",
            p: 2,
            width: "100%"

          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
});
