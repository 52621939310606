import React from "react"
import { Toaster } from "react-hot-toast"

export const ToastContainer = () => {
    return (
        <Toaster
            toastOptions={{
                success: {
                    style: {
                        background: '#1b5e20',
                        color: "#ffffff"

                    },
                },
                error: {
                    style: {
                        background: '#c62828',
                        color: "#ffffff"
                    },
                },
            }}
        />
    )
}
