import axios, { AxiosResponse } from "axios";
import { makeAutoObservable } from "mobx";

import { ILoginPayload, IObjectResponse, ITokenEntity, IUserEntity } from "../../common";

export class User implements IUserEntity {
  public id: string = "";
  public email: string = "";
  public password?: string = undefined;
  public tokens?: ITokenEntity[] = undefined;
  public createdAt: number = Date.now();
  public updatedAt: number = Date.now();

  public constructor(input?: IUserEntity) {
    if (input) {
      this.copyFrom(input);
    }

    makeAutoObservable(this);
  }

  public copyFrom(input: IUserEntity) {
    this.id = input.id;
    this.email = input.email;
    this.password = input.password;
    this.tokens = input.tokens;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
  }
}

export class AuthStore {
  public loading: boolean = true;
  public user?: User = undefined;

  public constructor() {
    makeAutoObservable(this);
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  private setUser(user?: User) {
    this.user = user;
  }

  public async login(email: string, password: string) {
    this.setLoading(true);

    try {
      const response = await axios.post<ILoginPayload, AxiosResponse<IObjectResponse<IUserEntity>>>("/api/auth/login", { email, password });

      if (response.data && response.data.data) {
        this.setUser(new User(response.data.data));

        return response;
      }
    } catch (error) {
      toast.error(error);
      console.error("Failed to login!", error);

      // TODO: Show toast message from error.response.data.message
    } finally {
      this.setLoading(false);
    }

    return false;
  }

  public async logout() {
    this.setLoading(true);

    try {
      const response = await axios.post("/api/auth/logout");

      this.setUser(undefined);

      return response;
    } catch (error) {
      console.error("Failed to logout!", error);
    } finally {
      this.setLoading(false);
    }

    return false;
  }

  public async me() {
    this.setLoading(true);

    try {
      const response = await axios.get<IObjectResponse<IUserEntity>>("/api/auth/me");

      if (response.data && response.data.data) {
        this.setUser(new User(response.data.data));
      }
    } catch (error) {
      console.error("Failed to get user!", error);
    } finally {
      this.setLoading(false);
    }
  }
}
