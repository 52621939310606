import axios, { AxiosResponse } from "axios";
import { makeAutoObservable } from "mobx";

import { IObjectResponse, IOrderEntity, IOrderPayload, IUserEntity } from "../../common";

export class Order implements IOrderEntity {
  public id: string = "";
  public orderDate: number = Date.now();
  public subOrderId: string = "";
  public size: string = "";
  public jobId: number = 0;
  public imageCount: number = 0;
  public mobileNumber: string = "";
  public createdAt: number = Date.now();
  public updatedAt: number = Date.now();
  public totalOrdersOnDay: number = 0;
  public totalOrders: number = 0;
  public orderSource: string = "";
  public returnedOrders: number = 0;
  public downloaded: boolean = false;
  public downloadedDate: number = 0;
  public cancelled: boolean = false;
  public cancelledDate: number = 0;
  public dispatched: boolean = false;
  public dispatchedDate: number = 0;
  public returned: boolean = false;
  public returnedDate: number = 0;

  public constructor(input?: IOrderEntity) {
    if (input) {
      this.copyFrom(input);
    }

    makeAutoObservable(this);
  }

  public copyFrom(input: IOrderEntity) {
    this.id = input.id;
    this.orderDate = input.orderDate;
    this.subOrderId = input.subOrderId;
    this.orderSource = input.orderSource;
    this.mobileNumber = input.mobileNumber;
    this.size = input.size;
    this.downloaded = input.downloaded;
    this.cancelled = input.cancelled;
    this.downloadedDate = input.downloadedDate;
  }
}

export class OrderStore {
  public loading: boolean = true;

  public constructor() {
    makeAutoObservable(this);
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  public async getOrder() {
    this.setLoading(true);

    try {
      const response = await axios.get("api/orders?pageNumber=1&pageSize=25");

      if (response) {
        return response?.data;
      }
    } catch (error) {
      console.error("Order error!", error);
      // TODO: Show toast message from error.response.data.message
    } finally {
      this.setLoading(false);
    }

    return false;
  }

  public async orderAdd(
    orderDate: number,
    orderSource: string,
    subOrderId: string,
    mobileNumber: string,
    size: string,
    downloaded: boolean,
    downloadedDate: number,
    cancelled: boolean,
    cancelledDate: number,
    dispatched: boolean,
    dispatchedDate: number,
    returned: boolean,
    returnedDate: number
  ) {
    this.setLoading(true);

    try {
      const response = await axios.post<IOrderPayload, AxiosResponse<IObjectResponse<IUserEntity>>>("api/orders", {
        orderDate,
        orderSource,
        subOrderId,
        mobileNumber,
        size,
        downloaded,
        downloadedDate,
        cancelled,
        cancelledDate,
        dispatched,
        dispatchedDate,
        returned,
        returnedDate
      });

      if (response) {
        return response?.data;
      }
    } catch (error) {
      console.error("Order error!", error);
      // TODO: Show toast message from error.response.data.message
    } finally {
      this.setLoading(false);
    }

    return false;
  }
}
