import { Box, Button, Checkbox, Paper } from "@mui/material";
// import TablePagination from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";

import OrderAdd from "./Model/OrderAdd";
import OrderEdit from "./Model/OrderEdit";

export default function DashboardTable({ getOrder }: any) {
  const [show, setShow] = useState<boolean>(false);
  const [showLabel, setShowLabel] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseLabel = () => setShow(false);
  const handleShowLabel = () => setShow(true);

  // Edit order model
  const handleClick = async (id: any) => {
    await handleShow();
    // await (id);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 3, ml: 1, mb: 4, maxHeight: "100%" }}>
        <Table sx={{ minWidth: 400 }} aria-label='simple table'>
          <TableHead sx={{ backgroundColor: "#ddd" }}>
            <TableRow>
              <TableCell padding='checkbox'>
                <Box sx={{ p: 3 }}>
                  <Checkbox
                    // checked={selectAll}
                    color='primary'
                    // onChange={handleSelectAllChange}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
                    inputProps={{
                      "aria-label": "select all desserts"
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell className='tableHead' align='center'>
                S.No
              </TableCell>
              <TableCell className='tableHead' align='center'>
                Img
              </TableCell>
              <TableCell className='tableHead' align='center'>
                Order Date
              </TableCell>
              <TableCell className='tableHead' align='center'>
                Suborder ID
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                Mobile Number
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                T-O-C
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                A-O-C
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                R-C
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                Job ID <span className='selected'>{}</span>
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                Dispatch Date
              </TableCell>
              <TableCell className='tableHead' align='center'>
                Size
              </TableCell>
              <TableCell className='tableHead' sx={{ whiteSpace: "nowrap" }} align='center'>
                Order Action
              </TableCell>
              <TableCell className='tableHead' align='center'>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getOrder &&
              getOrder?.data?.map((row: any, index: any) => {
                return (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 }
                    }}
                  >
                    <TableCell padding='checkbox'>
                      <Box sx={{ p: 3 }}>
                        <Checkbox
                          color='primary'
                          //   checked={selectedIds.includes(row?._id)}
                          //   onChange={() => handleCheckBoxChange(row?._id)}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
                          inputProps={{
                            "aria-label": "select all desserts"
                          }}
                        />
                      </Box>
                    </TableCell>

                    <TableCell className='tableRow' align='center'>
                      {index + 1}
                    </TableCell>

                    <TableCell sx={{ color: "#d500f9" }} className='tableRow' align='center'>
                      {row?.imageCount}
                    </TableCell>

                    <TableCell className='tableRow' sx={{ whiteSpace: "nowrap" }} align='center'>
                      {row?.orderDate}
                    </TableCell>

                    <TableCell className='tableRow' align='center' sx={{}}>
                      {row?.subOrderId}
                    </TableCell>

                    <TableCell className='tableRow' align='center' sx={{ whiteSpace: "nowrap" }}>
                      {row.mobileNumber}
                    </TableCell>

                    <TableCell
                      className='tableRow'
                      align='center'
                      sx={{ whiteSpace: "nowrap", fontWeight: "800", color: "#d500f9", cursor: "pointer" }}
                    >
                      {row?.totalOrdersOnDay}
                    </TableCell>

                    <TableCell
                      className='tableRow'
                      align='center'
                      sx={{ whiteSpace: "nowrap", fontWeight: "800", color: "#d500f9", cursor: "pointer" }}
                    >
                      {row?.totalOrders}
                    </TableCell>

                    <TableCell className='tableRow' align='center' sx={{ fontWeight: "800", color: "#d500f9" }}>
                      {row.returnedOrders}
                    </TableCell>

                    <TableCell className='tableRow' align='center' sx={{}}>
                      {row.jobId}
                    </TableCell>
                    <TableCell className='tableRow' align='center' sx={{}}>
                      {row.dispatchedDate}
                    </TableCell>
                    <TableCell className='tableRow' sx={{ whiteSpace: "nowarp" }} align='center'>
                      {row.size}
                    </TableCell>
                    <TableCell className='tableRow' align='center'>
                      <Box>
                        <Checkbox
                          color='primary'
                          name='select_order'
                          style={{
                            color: "#008000",
                            padding: "10px"
                          }}
                          checked={row.select_order}
                          //   onChange={(e) => {
                          //     // formik.setFieldValue("select_order", e.target.checked);
                          //     handleCheckboxEdit(row?._id, { ...row, select_order: e.target.checked });
                          //   }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
                          inputProps={{
                            "aria-label": "select all desserts"
                          }}
                        />
                        <Checkbox
                          color='primary'
                          name='cancel_order'
                          style={{
                            color: "#FF0000"
                          }}
                          // readOnly
                          checked={row.cancel_order}
                          //   onChange={(e) => {
                          //     handleCheckboxEdit(row?._id, { ...row, cancel_order: e.target.checked });
                          //   }}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 17 },
                            "& .MuiTypography-root": { color: "green" } // Styling the label color
                          }}
                          inputProps={{
                            "aria-label": "select all desserts"
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className='tableRow' align='center'>
                      <Button sx={{ fontSize: "0.75rem" }} color='success' variant='contained' onClick={() => handleClick(row?._id)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        {/* <TablePagination
          style={{ fontSize: "0.8rem", fontWeight: 100 }}
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={paginationCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <InputLabel
              style={{
                fontSize: "1rem",
                fontFamily: "InterLight, sans-serif",
                position: "absolute",
                left: 15,
                top: 0,
                margin: "10px 0"
              }}
            >
              Rows per page
            </InputLabel>
          }
          SelectProps={{
            style: {
              fontSize: "0.8rem",
              position: "absolute",
              left: 140
            }
          }}
        /> */}
      </TableContainer>

      {/* <OrderEdit show={show} setShow={setShow} handleClose={handleClose} /> */}
      <OrderAdd showLabel={showLabel} handleCloseLabel={handleCloseLabel} />
    </div>
  );
}
