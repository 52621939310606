import React, { useEffect, useState } from "react";

import { useStore } from "../../../store";
import DashboardTable from "./DashboardTable";

export const DashboardContent = () => {
  const { order } = useStore();
  const [getOrder, setGetOrder] = useState<any[]>([]);

  const fetchOrder = async () => {
    await order
      .getOrder()
      .then((res) => setGetOrder(res))
      .catch((err) => console.log(err, "error"));
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  console.log(getOrder);

  return (
    <div>
      <DashboardTable getOrder={getOrder} />
    </div>
  );
};

export default DashboardContent;
