import React from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import sideBarLayout from "./SidebarLayout";
import { drawerWidth } from "../../shared/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../asset/logo.png";
import useDrawer from "../../hooks/index";
import { useStore } from "../../store";
import { toast } from "react-hot-toast";


const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const Sidebar = () => {
    const { isOpen } = useDrawer();
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;

    const { auth } = useStore();

    const handleClear = (id: any) => {
        if (id === 5) {
            handleLogout();
        }
    }

    const handleLogout = async () => {
        await auth.logout().then((res: any) => {
            toast.success(res?.data?.message);
            navigate("/login")
        }).catch(err => console.log(err, "error"));
    }


    const handleSubMenuClick = (subPath: any) => {
        // Find the parent menu item with id 4
        const settingsItem = sideBarLayout.find(item => item.id === 4);
        if (settingsItem) {
            // Update the path of the parent menu item
            settingsItem.path = subPath;
            // Navigate to the subPath
            navigate(subPath);
        }
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    borderRight: "none",
                },
            }}
            variant="persistent"
            anchor="left"
            open={isOpen}
        >
            <DrawerHeader>
                <img
                    src={Logo}
                    alt="logo"
                    style={{ width: "100px", marginRight: "26%", marginTop: "10px" }}
                />
            </DrawerHeader>

            <List sx={{ mt: 3 }}>
                {sideBarLayout.map((item) => (
                    <React.Fragment key={item.id}>
                        <ListItem
                            disablePadding
                            component={Link}
                            to={item.path}
                            selected={item.path === path}
                        >
                            <ListItemButton
                                sx={
                                    item.path === path
                                        ? { ...sidebarActiveMenu }
                                        : { mx: 1.5, my: 0.6, py: 0.4 }
                                }
                                onClick={() => handleClear(item.id)}
                            >
                                <ListItemIcon
                                    sx={{
                                        p: 1,
                                        color: item.path === path ? "#ffffff" : "#8d8d8d",
                                    }}
                                >
                                    {item.path === path ? item?.activeIcon : item?.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{
                                                color: item.path === path ? "#FFFFFF" : "#434343",
                                                fontWeight: 500,
                                                fontSize: "0.75rem",
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {/* Render sub-menu items if Settings is selected */}
                        {item?.path === path && item.subMenu && (
                            <List sx={{ ml: 2 }}>
                                {item.subMenu.map((subItem) => (
                                    <ListItem
                                        key={subItem.id}
                                        disablePadding
                                        component={Link}
                                        to={subItem.path}
                                        onClick={() => handleSubMenuClick(subItem.path)}
                                        selected={subItem.path === path}
                                    >

                                        <ListItemButton
                                            sx={
                                                subItem.path === path
                                                    ? { ...sidebarActiveMenu }
                                                    : { mx: 1.5, my: 0.6, py: 0.4 }
                                            }
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    p: 0.2,
                                                    color: subItem.path === path ? "#ffffff" : "#8d8d8d",
                                                }}
                                            >
                                                {subItem.path === path ? item?.activeIcon : item?.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{
                                                            color:
                                                                subItem.path === path ? "#ffffff" : "#8d8d8d",
                                                            fontWeight: 500,
                                                            fontSize: "0.75rem",
                                                        }}
                                                    >
                                                        {subItem?.name}
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;

const sidebarActiveMenu = {
    background: "#1976d2",
    borderRadius: "8px",
    mx: 1.5,
    my: 0.6,
    py: 0.6,
    // mb: 1,
    "&:hover": {
        background: "#1976d2",
    },
};
