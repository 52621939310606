import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginValidation from "./LoginValidation"
import toast from "react-hot-toast";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Typography,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Logo from "../../../asset/logo.png";
import axios from "axios";

import { useStore } from "../../../store";

export const LoginForm: React.FC = observer(() => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { auth } = useStore();

  const handleFormSubmit = async (values: any) => {
    await auth.login(values.email, values.password).then((res: any) => {
      toast.success(res?.data?.message);
      navigate("/dashboard")
    }).catch(err => console.log(err, "error"));
  }


  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginValidation,
    onSubmit: handleFormSubmit,
  });

  return (
    <Box sx={customStyles.container}>
      <Box sx={customStyles.formContainer}>
        <Box
          component="img"
          src={Logo}
          sx={{ width: "25%", mx: "auto", display: "block" }}
          alt="VarietyLogo"
        />
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "19px",
              fontWeight: 600,
              pt: 3,
            }}
          >
            Sign in
          </Typography>
          <Typography
            sx={{ color: "lightColor.contrastText", fontSize: "12px", pt: 1.5 }}
          >
            Enter your email and password to access your <br /> account
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ m: 1, width: "35ch" }} variant="standard">
              <TextField
                label="Email "
                name="email"
                autoComplete="off"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.email && formik.errors.email)}
              />
              <Typography sx={{
                fontSize: "13px",
                textAlign: 'left',
                mt: 1,
                ml: 0.5,
                color: (theme) => theme.palette.error.main,
              }}>
                {formik.touched.email && formik.errors.email}
              </Typography>
            </FormControl>

            <FormControl sx={{ m: 2, width: "35ch" }} variant="standard">
              <TextField
                name="password"
                label="Password"
                size="small"
                variant="outlined"
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                onChange={formik.handleChange}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility sx={{ fontSize: "1.3rem" }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: "1.3rem" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: 'left',
                  mt: 1,
                  ml: 0.5,
                  color: (theme) => theme.palette.error.main,
                }}
              >
                {formik.touched.password && formik.errors.password}
              </Typography>
            </FormControl>
            <Button
              size={"medium"}
              variant="contained"
              type={"submit"}
              sx={{ width: "72%", fontWeight: 700 }}
            >
              Sign IN
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
});


// Login Form Styles
const customStyles = {
  container: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    bgcolor: "white",
    borderRadius: 2,
    p: 4,
    maxWidth: 400,
  },
  InputField: {
    borderBottomColor: "#BD4ED7", // Replace with the desired border color
    borderBottomWidth: 1.8, // You can adjust the width of the border
    borderBottomStyle: "solid",
    "&:hover": {
      borderBottomColor: "#BD4ED7", // Replace with the desired hovered border color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#BD4ED7", // Replace with the desired focused border color
    },
  },
};
